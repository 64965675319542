    <template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">{{val.title}}</div>
            <div class="d-flex flex-row align-center justify-start subtitle-1 font-weight-light">
              <span>{{val.duration > 1 ? `${val.duration} Days` : `${val.duration} Day`}}</span>
              <span class="mx-3">•</span>
              <span>{{item.status}}</span>
            </div>
          </template>
          <v-card-text>
            <v-row class="d-flex flex-row align-start justify-space-between">
              <!-- rfp details column -->
              <v-col cols="12" md="7" lg="8">
                <v-row class="d-flex flex-row align-start justify-space-between">
                  <!-- event summary column -->
                  <v-col cols="12" sm="6">
                    <h2 class="title primary--text">Event Summary</h2>
                    <v-col cols="12" class="px-0">
                      <!-- individual row for event summary details -->
                      <v-row class="px-0">
                        <v-col cols="8">No. Delegates</v-col>
                        <v-col cols="4">{{val.noOfDelegates}}</v-col>
                      </v-row>
                      <!-- end of individual row for event summary details -->
                      <!-- individual row for event summary details -->
                      <v-row class="px-0">
                        <v-col cols="8">No. Hotel Rooms</v-col>
                        <v-col cols="4">{{val.noOfHotelRooms}}</v-col>
                      </v-row>
                      <!-- end of individual row for event summary details -->
                      <!-- individual row for event summary details -->
                      <v-row class="px-0">
                        <v-col cols="8">No. Breakout Rooms</v-col>
                        <v-col cols="4">{{val.noOfBreakoutRooms}}</v-col>
                      </v-row>
                      <!-- end of individual row for event summary details -->
                    </v-col>
                  </v-col>
                  <!-- end of event summary column -->
                  <!-- event city/country exclusion column -->
                  <v-col cols="12" sm="6">
                    <h2 class="title primary--text">Counties/Cities to Exclude</h2>
                    <v-row class="d-flex flex-row flex-wrap">
                      <!-- <v-chip class="ma-2 grey--text" label>City, Country</v-chip>
                      <v-chip class="ma-2 grey--text" label>City, Country</v-chip>
                      <v-chip class="ma-2 grey--text" label>City, Country</v-chip>
                      <v-chip class="ma-2 grey--text" label>City, Country</v-chip>
                      <v-chip class="ma-2 grey--text" label>City, Country</v-chip>-->
                      <v-chip
                        class="ma-2 grey--text"
                        label
                        :key="index"
                        v-for="(city,index) in val.cityMatch.cityExcludeGoogleId"
                      >{{city}}</v-chip>
                    </v-row>
                  </v-col>
                  <!-- end of event city/country exclusion column -->
                  <!-- shortlisted cities and venues selection -->
                  <v-col cols="12">
                    <h2 class="title primary--text">Short Listed Cities</h2>
                    <div v-for="(cityShortList,index) in val.cityShortList" :key="index">
                      <span style="text-transform: capitalize">{{cityShortList.continent}}</span>
                      <br />
                      {{cityShortList.formattedAddress}}
                      <br />
                      <br />
                    </div>
                  </v-col>
                  <!-- end shortlisted cities and venues selection -->
                </v-row>
              </v-col>
              <!-- end of rfp details column -->
              <!-- organiser details column -->
              <v-col
                class="d-flex flex-column align-center align-md-end justify-center"
                cols="12"
                md="5"
                lg="4"
              >
                <v-sheet class="user-side-card" color="white" elevation="4">
                  <v-col
                    class="d-flex flex-column flex-sm-row flex-md-column align-center justify-center px-3 py-4"
                  >
                    <!-- user details -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                      class="d-flex flex-column align-center justify-center"
                    >
                      <!-- user avatar -->
                      <v-row class="avatar-rows">
                        <v-avatar class="d-none d-md-flex" size="150" color="white">
                          <v-img
                            :src="val.organiserProfile.logo.length> 0 ? val.organiserProfile.logo[0] : 'https://st3.depositphotos.com/13159112/17145/v/1600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg' "
                            alt="Logo"
                          ></v-img>
                        </v-avatar>
                        <v-avatar class="d-flex d-md-none" size="100" color="primary">
                          <v-img
                            :src="val.organiserProfile.logo.length> 0 ? val.organiserProfile.logo[0] : 'https://st3.depositphotos.com/13159112/17145/v/1600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg' "
                            alt="John"
                          ></v-img>
                        </v-avatar>
                      </v-row>
                      <!-- end of user avatar -->
                      <!-- the user's name -->
                      <h1 class="headline user-name text-center py-1">{{val.user.name}}</h1>
                      <!-- the organisation name -->
                      <h3
                        class="subtitle-1 organiation-name text-center pb-1"
                      >{{val.user.organisation}}</h3>
                      <!-- user's email address -->
                      <h5 class="subtitle-2 text-center py-1 platinum--text">{{val.user.email}}</h5>
                      <!-- member joined date (dd.mm.yy), only visible on sm -->
                      <v-row
                        class="d-none d-sm-flex d-md-none pt-2"
                        align="center"
                        justify="center"
                      >
                        <h6
                          class="caption text-center"
                        >Member Since: {{val.user.created_at.split("T")[0]}}</h6>
                      </v-row>
                    </v-col>
                    <!-- end of user details -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="12"
                      class="d-flex flex-column align-center justify-center"
                    >
                      <!-- social media link icons (will load dynamically based on all the registered SM links [Name, Icon, and URL]) -->
                      <v-row
                        class="d-flex flex-row align-self-start flex-wrap"
                        align="start"
                        justify="start"
                      >
                        <v-col
                          cols="2"
                          class="px-2 py-1 mx-1"
                          v-for="socialMedia in computedSocialMedia"
                          :key="socialMedia._id"
                        >
                          <template v-if="socialMedia.link">
                            <v-btn
                              class="d-flex align-center justify-center"
                              color="platinum"
                              target="_blank"
                              :href="socialMedia.link"
                              icon
                              tile
                              small
                            >
                              <font-awesome-icon :icon="['fab', socialMedia.icon]" size="2x" />
                            </v-btn>
                          </template>
                        </v-col>
                      </v-row>
                      <!-- end of social media links -->
                    </v-col>
                    <!-- member joined date (dd.mm.yy), hidden on  -->
                    <v-row class="d-none d-md-flex pt-2" align="center" justify="center">
                      <h6
                        class="caption text-center"
                      >Member Since: {{val.user.created_at.split("T")[0]}}</h6>
                    </v-row>
                  </v-col>
                </v-sheet>
              </v-col>
              <!-- end of organiser details column -->
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RFPApicalls from "../../../services/rfp.service";

export default {
  name: "RFPView",
  data() {
    return {
      val: {
        _id: "5e731766796dee37dc8fb18a",
        isExibitSpace: false,
        subject: [],
        type: "Match",
        title: "Testinng with Ex and in",
        noOfDelegates: 17000,
        startDate: "2020-03-19T00:00:00.000Z",
        endDate: "2020-03-31T00:00:00.000Z",
        duration: 12,
        noOfHotelRooms: 17000,
        noOfBreakoutRooms: 12337,
        exibitSpace: 0,
        user: {
          _id: "5e623ad2ddfb6d2a68ee8c83",
          userType: "organiser",
          name: "Senthu",
          email: "senthu1115@gmail.com",
          organisation: "ABD"
        },
        cityMatch: {
          cityExcludeGoogleId: []
        },
        cityShortList: []
      },
      item: {
        id: 2,
        trophy: false,
        organisation: "TED Corporation",
        title: "TEDx Manila 2020",
        representative: {
          name: "Sage Rodriguez",
          email: "sage@tedcorp.com",
          date: "01/12/2019",
          logo: [
            "https://st3.depositphotos.com/13159112/17145/v/1600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg"
          ]
        },
        type: "Match",
        duration: 1,
        cities: "3",
        venues: "4",
        delegates: "330",
        hotel: "400",
        breakout: "15",
        exhibition: "190",
        topic: "Technology",
        status: "Pending"
      }
    };
  },
  mounted() {
    // console.log();
    let payload = {
      _id: this.$route.params.id
    };
    RFPApicalls.getRFPById(payload)
      .then(res => {
        this.val = res.data;
        // this.items = res.data;
      })
      // .catch(err => {
      //   console.log(err);
      // });
  },
  methods: {}
};
</script>
<style scoped>
.user-side-card {
  position: relative;
  width: 250px;
  min-height: 600px;
  overflow: hidden;
}
.user-side-card .user-name,
.user-side-card .organiation-name {
  text-transform: capitalize;
}
/* Medium devices (large tablet to laptop, less than 992px) */
@media (max-width: 991.98px) {
  .user-side-card {
    width: 80vw;
    min-width: 200px;
    min-height: 250px;
  }
}
</style>